<h4 style="text-align: center; margin-top: 16px">Setup integration</h4>

<div class="items">
  <button
    mat-raised-button
    (click)="openSetupDialog('JIRA')"
  >
    <mat-icon svgIcon="jira"></mat-icon>
    <span>Jira</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITHUB')"
  >
    <mat-icon svgIcon="github"></mat-icon>
    <span>GitHub</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('REDMINE')"
  >
    <mat-icon svgIcon="redmine"></mat-icon>
    <span>Redmine</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITLAB')"
  >
    <mat-icon svgIcon="gitlab"></mat-icon>
    <span>GitLab</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('CALDAV')"
  >
    <mat-icon svgIcon="caldav"></mat-icon>
    <span>CalDAV</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('OPEN_PROJECT')"
  >
    <mat-icon svgIcon="open_project"></mat-icon>
    <span>Open Project</span>
  </button>
  <button
    mat-raised-button
    (click)="openSetupDialog('GITEA')"
  >
    <mat-icon svgIcon="gitea"></mat-icon>
    <span>Gitea</span>
  </button>
</div>
